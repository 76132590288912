// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.dev.ts` with `environmentDev.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const SYSTEM_NAME = 'HELIOS';
export const SYSTEM_NAME_AUTH = 'AUTH';

export const environment = {
    production: false,
    useHttps: true,
    isLocal: true,
    systemName: SYSTEM_NAME,
    organizationName: 'Univerzitná knižnica',
    organizationWebsiteUrl: 'https://www.tuke.sk/',
    organizationSupportEmail: 'kniznica@tuke.sk',
    organizationSupportPhone: '+421 55 602 7700',
    availableLanguages: ['sk', 'en'],
    defaultLocale: 'sk',
    API_URL: `https://api.helios.portal.dev.msweb.tuke.sk/api`,
    BASE_API_URL: `https://auth.api.portal.dev.msweb.tuke.sk`,
    userRoles: {
        SuperAdmin: 'SUPERADMIN',
        All: [
            'SUPERADMIN',
        ],
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
