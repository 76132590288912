import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import {
  APP_URL_ALFRED,
  APP_URL_EPC,
  APP_URL_ETD,
  APP_URL_HELPDESK,
  APP_URL_ISBN,
  APP_URL_LIBADMIN,
  APP_URL_ROOMBOOK,
  APP_URL_TOOLS,
  APP_URL_UIK,
  APP_URL_ZP,
  AUTH_BASE_COOKIE_DOMAIN,
  AUTH_REDIRECT_URL
} from "./auth.urls";
import { AUTH_RETURN_URL_COOKIE_EXPIRATION } from './auth-constants';
import {environment} from '../../../environments/environment.dev';
import {LOCAL_FRONTEND_URL} from '../urls';

@Injectable()
export class AppRedirectService {
  constructor(private router: Router, private cookieService: CookieService, @Inject(DOCUMENT) private document: any) {}

  redirectLogin(sendReturnUrl: boolean = false): void {
    if (!environment.production && environment.isLocal) {
      this.document.location.href =
        AUTH_REDIRECT_URL + '?development=true&redirectAfterLogin=' + encodeURIComponent(LOCAL_FRONTEND_URL + '/#/dev');
    } else {
      if (sendReturnUrl) {
        const expires = Date.now() + AUTH_RETURN_URL_COOKIE_EXPIRATION;
        this.cookieService.set(
          'auth_ret',
          encodeURIComponent(this.document.location.href),
          expires,
          '/',
          AUTH_BASE_COOKIE_DOMAIN,
          environment.production
        );
      } else if (this.cookieService.check('auth_ret')) {
        this.cookieService.delete('auth_ret', '/', AUTH_BASE_COOKIE_DOMAIN);
      }

      this.document.location.href = AUTH_REDIRECT_URL;
    }
  }

  redirect(url: string): void {
    this.document.location.href = url;
  }

  redirectEpc(): void {
    this.document.location.href = APP_URL_EPC;
  }

  redirectEtd(): void {
    this.document.location.href = APP_URL_ETD;
  }

  redirectIsbn(): void {
    this.document.location.href = APP_URL_ISBN;
  }

  redirectRoombook(): void {
    this.document.location.href = APP_URL_ROOMBOOK;
  }

  redirectZp(): void {
    this.document.location.href = APP_URL_ZP;
  }

  redirectUik(): void {
    this.document.location.href = APP_URL_UIK;
  }

  redirectHelpdesk(): void {
    this.document.location.href = APP_URL_HELPDESK;
  }

  redirectTools(): void {
    this.document.location.href = APP_URL_TOOLS;
  }

  redirectAlfred(): void {
    this.document.location.href = APP_URL_ALFRED;
  }

  redirectLibadmin(): void {
    this.document.location.href = APP_URL_LIBADMIN;
  }
}
