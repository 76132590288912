import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { AppRedirectService } from '../app-redirect.service';
import {environment} from '../../../../environments/environment.dev';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private appRedirectService: AppRedirectService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let returnUrl = true;

    if (!environment.production && environment.isLocal) {
      if (
        route.queryParams &&
        route.queryParams.development &&
        decodeURIComponent(route.queryParams.development) === 'true'
      ) {
        if (route.queryParams.user) {
          this.authenticationService.saveLoggedUserFromEncrypted(decodeURIComponent(route.queryParams.user));
        }
      }
    } else {
      if (route.url && route.url[0] && route.url[0].path === 'dev') {
        this.router.navigate(['']);
        return false;
      }
    }

    if (this.authenticationService.checkForUserCookie() && !AuthenticationService.isSessionExpired()) {
      this.authenticationService.checkTokenRefreshRequirement();

      if (AuthenticationService.checkSystemAccessPermission()) {
        return true;
      } else {
        returnUrl = false;
      }
    }

    this.appRedirectService.redirectLogin(returnUrl);
    return false;
  }
}
