import { TranslateDefaultParser } from '@ngx-translate/core';
import {Injectable} from '@angular/core';

/**
 * Enables using placeholder using %placeholder% syntax instead of {{ placeholder }}
 * Useful when keys are also viewable texts and {{ }} syntax would mess up with angular template syntax
 *
 * In template:
 *
 * <div translate [translateParams]="{who: 'world'}">Hello %who%</div>
 * <div>{{ 'Hello %who%' | translate:{who: 'world'} }}</div>
 *
 * With original {{ }} syntax, this would reult in an error
 * <div translate [translateParams]="{who: 'world'}">Hello {{ who }}</div>
 * <div>{{ 'Hello {{ who }}' | translate:{who: 'world'} }}</div>
 *
 *
 * Usage - in NgModule definition when configuring imported TranslateModule:
 *
 * 		TranslateModule.forRoot({
 *			// loaders, missing translation handlers, etc
 *			parser: {
 *				provide: TranslateParser,
 *				useClass: PercentageSignTranslateParser,
 *			},
 *		}),
 *
 */
@Injectable()
export class PercentageSignTranslateParser extends TranslateDefaultParser {

	protected regexp = new RegExp(/%\s?([a-z0-9\-_]+)\s?%/, 'ig');

	override interpolate(expr: string | Function, params?: any): string {
		if (typeof expr === 'string') {
			return super.interpolate(
				expr.replace(
					this.regexp,
					(all, innerPart) => `{{ ${innerPart} }}`
				),
				params
			);
		}
		return super.interpolate(expr, params);
	}

}
