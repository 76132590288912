import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../environments/environment.dev';
import {AuthenticationService} from '../auth/authentication.service';

@Pipe({
    name: 'userRole',
})
export class UserRolePipe implements PipeTransform {

    static getCurrentUserRoles(): any[] {
        return AuthenticationService.getStoredAuthData()?.user?.roles || [];
    }

    transform(requestedRoles: string[], reverse?: boolean): any {
        return true;
        // const POSSIBLE_ROLES = environment.userRoles.All;
        // const currentUserRoles = UserRolePipe.getCurrentUserRoles();
        //
        // if (!requestedRoles) {
        //     return;
        // }
        //
        // if (reverse) {
        //     return !currentUserRoles.every(role => requestedRoles.includes(role));
        // }
        //
        // if (typeof requestedRoles === 'string') {
        //     requestedRoles = [requestedRoles];
        // }
        //
        // if (POSSIBLE_ROLES.some(roles => currentUserRoles.includes(roles))) {
        //     return requestedRoles.some(roles => currentUserRoles.includes(roles));
        // } else {
        //     return false;
        // }
    }
}
