export enum UkAppsEnum {
    AUTH = 'AUTH',
    EPC = 'EPC',
    ETD = 'ETD',
    ISBN = 'ISBN',
    ROOMBOOK = 'ROOMBOOK',
    ZP = 'ZP',
    UIK = 'UIK',
    HELPDESK = 'HELPDESK',
    TOOLS = 'TOOLS',
    LIBADMIN = 'LIBADMIN',
    ALFRED = 'ALFRED',
    HELIOS = 'HELIOS'
}

export enum UkAppsFullNameEnum {
    AUTH = 'AUTH',
    EPC = 'EPC',
    ETD = 'thesisEvidence',
    ISBN = 'ISBN',
    ROOMBOOK = 'roombook',
    ZP = 'ZP',
    UIK = 'UIK',
    HELPDESK = 'HELPDESK',
    TOOLS = 'TOOLS',
    LIBADMIN = 'libadmin',
    ALFRED = 'ALFRED',
    HELIOS = 'HELIOS'
}
