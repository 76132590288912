import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import * as _ from 'lodash';
import {EMPTY, Observable, of, Subject, throwError} from 'rxjs';
import {AppRedirectService} from '../app-redirect.service';
import {getLoginUrl, getLogoutUrl} from '../auth.urls';
import {AuthenticationService} from '../authentication.service';
import {CURRENT_API_HOST_URL} from '../../urls';
import {catchError, map, switchMap, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptor {
    private calledLogout = false;
    private tokenRefreshedSubject: Subject<any> = new Subject<any>();
    private tokenRefreshed: Observable<any> = this.tokenRefreshedSubject.asObservable();

    private interruptLogout = false;

    constructor(private injector: Injector) {
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // Ak bude potrebne pouzit v interceptore nejaku service, tak referenciu tahat cez Injector, nie cez DI konstruktora!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        const authenticationService: AuthenticationService = this.injector.get(AuthenticationService);

        const appRedirectService: AppRedirectService = this.injector.get(AppRedirectService);

        const headersToSet = {};

        const access_token = AuthenticationService.getStoredAuthData()?.access_token || null;

        if (req.headers) {
            if (!req.headers.has('Content-Type')) {
                headersToSet['Content-Type'] = 'application/json';
            }

            if (access_token && !req.headers.has('Authorization')) {

                headersToSet['Authorization'] = 'Bearer ' + access_token;
            }
        }

        req = req.clone({
            setHeaders: headersToSet,
        });

        return next.handle(req)
            .pipe(tap({
                next: (response) => {
                    if (response instanceof HttpResponse) {
                        if (response.status === 200) {
                            if (_.startsWith(response.url, getLogoutUrl())) {
                                this.logout(response, authenticationService, appRedirectService);
                            }
                        }
                    }
                },
            }))
            .pipe(
                catchError((error) => {

                    if (error.status === 401) {
                        this.logout(error, authenticationService, appRedirectService);
                    }

                    if (
                        !(error instanceof HttpErrorResponse) ||
                        !_.startsWith((error as HttpErrorResponse).url, CURRENT_API_HOST_URL)
                    ) {
                        return throwError(error.error);
                    }

                    if (_.startsWith(error.url, getLoginUrl())) {
                        if (error.status !== 400) {
                            // TODO: Zobrazit error toast ak je potrebne
                        } else {
                            this.logout(error, authenticationService, appRedirectService);
                        }

                        return throwError(error.error);
                    } else if (_.startsWith(error.url, getLogoutUrl())) {
                        if (error.status !== 401) {
                            // TODO: Zobrazit error toast ak je potrebne
                        }

                        return throwError(error.error);
                    } else if (_.startsWith(error.url, getSessionValidityCheckUrl())) {
                        if (error.status !== 401) {
                            // TODO: Zobrazit error toast ak je potrebne
                        } else {
                            this.logout(error, authenticationService, appRedirectService);
                        }

                        return throwError(error.error);
                    } else if (error.status === 401) {
                        if (AuthenticationService.isSessionExpired()) {
                            // TODO: Zobrazit error toast ze nastala chyba pri odhlasovani ak je potrebne

                            this.logout(error, authenticationService, appRedirectService);
                        } else if (
                            !AuthenticationService.checkExpirationTime() &&
                            AuthenticationService.checkRefreshExpirationTime()
                        ) {
                            return this.refreshToken(authenticationService).pipe(
                                switchMap(() => {
                                    return next.handle(req);
                                }),
                                catchError(refreshError => {
                                    // TODO: Zobrazit error toast ze nastala chyba autentifikacie ak je potrebne

                                    if (refreshError.status === 400 || refreshError.status === 401 || refreshError.status === 500) {
                                        this.logout(refreshError, authenticationService, appRedirectService);

                                        return EMPTY;
                                    }

                                    return throwError(error.error);
                                }),
                            );
                        } else {
                            // TODO: Zobrazit error toast 401 ak je potrebne

                            this.logout(error, authenticationService, appRedirectService);
                        }
                    } else if (error.status < 200 || error.status > 299) {
                        // TODO: Zobrazit error toast ak je potrebne ked sa vyskytne neocakavany stav alebo chyba protokolu
                    }
                }),
            );
    }

    setInterruptLogout(interruptLogout: boolean) {
        this.interruptLogout = interruptLogout;
    }

    private logout(
        response: HttpResponse<any> | HttpErrorResponse,
        authenticationService: AuthenticationService,
        appRedirectService: AppRedirectService,
    ): void {

        if (this.interruptLogout) {
            return;
        }

        if (this.calledLogout) {
            return;
        }

        this.calledLogout = true;
        authenticationService.logout().subscribe({
            next: () => {
                this.calledLogout = false;
                appRedirectService.redirectLogin(false);
            },
            error: error => {
                this.calledLogout = false;
                appRedirectService.redirectLogin(false);
            },
        });
    }

    private refreshToken(authenticationService: AuthenticationService): Observable<any> {
        if (authenticationService.tokenRefreshInProgress) {
            return new Observable(subscriber => {
                this.tokenRefreshed.subscribe({
                    next: () => {
                        subscriber.next();
                        subscriber.complete();
                    },
                    error: error => {
                        subscriber.error(error);
                        subscriber.complete();
                    },
                });
            });
        } else {
            const currentSession = AuthenticationService.getStoredAuthData();

            if (_.isNil(currentSession)) {
                return throwError('No session present!');
            }

            return authenticationService
                .getNewToken(currentSession.user.login, currentSession.refresh_token, currentSession.session_guid)
                .pipe(
                    tap({
                        next: (d) => {
                            this.tokenRefreshedSubject.next(d);
                        },
                        error: error => {
                            // console.log(error);
                            this.tokenRefreshedSubject.error(error);
                        },
                    }),
                );
        }
    }
}

function getSessionValidityCheckUrl(): string {
    throw new Error('Function not implemented.');
}

