import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import {UserRolePipe} from '../../pipes/user-role.pipe';

@Injectable()
export class RoleGuard implements CanActivate {
    constructor(
        private userRolePipe: UserRolePipe,
        private roles: string[]
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // const POSSIBLE_ROLES = UserRoles.All;
        const POSSIBLE_ROLES = [];
        const CURRENT_USER_ROLES = AuthenticationService.getStoredAuthData().user.roles;

        const roles = route.data.roles as Array<string>;

        if (!roles) {
            return false;
        }

        return roles.some(role => {
            if (POSSIBLE_ROLES.some(rols => CURRENT_USER_ROLES.includes(rols))) {
                return [role].some(rols => CURRENT_USER_ROLES.includes(rols));
            } else {
                return false;
            }
        });
    }
}
