import {NgModule} from '@angular/core';
import {InlineLoaderModule} from './components/inline-loader/inline-loader.module';
import {RoleGuard} from './auth/guards/role.guard';
import {AuthenticationService} from './auth/authentication.service';
import {CookieService} from 'ngx-cookie-service';
import {AppRedirectService} from './auth/app-redirect.service';
import {AUTH_INTERCEPTOR_PROVIDER} from "./auth/interceptors/auth-interceptor.provider";

@NgModule({
    imports: [
        InlineLoaderModule,
    ],
    exports: [],
    declarations: [
    ],
    providers: [
        AUTH_INTERCEPTOR_PROVIDER,
        RoleGuard,
        AuthenticationService,
        CookieService,
        AppRedirectService,
    ],
})

export class CommonModule {
}
