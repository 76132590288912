// base URL for frontend (non-local)
import {environment, SYSTEM_NAME_AUTH} from '../../environments/environment.dev';
import {StringUtils} from './utils/string-utils';

export const LOCAL_HOST = 'localhost';         // local frontend host (default localhost)
export const LOCAL_FRONTEND_PORT = '3003';     // local frontend port
export const PROD_HOST = 'portal.tuke.sk';         // production hostname
export const PROD_HOST_PORT = '';                      // production host port (empty for default)
export const PROD_API_HOST = 'portal.tuke.sk';        // production API hostname
export const PROD_API_HOST_PORT = '';                  // production API host port (empty for default)
export const DEV_HOST = 'portal.dev.msweb.tuke.sk';      // development hostname
export const DEV_HOST_PORT = '';                       // development host port (empty for default)
export const DEV_API_HOST = 'portal.dev.msweb.tuke.sk';     // development API hostname
export const DEV_API_HOST_PORT = '';                      // development API host port (empty for default)

export const BASE_URL = getBaseApiUrl(environment.systemName);

export const LOCAL_FRONTEND_URL = `${getClientProtocol()}://${LOCAL_HOST}:${LOCAL_FRONTEND_PORT}`;
export const CURRENT_API_HOST_URL = getCurrentApiHost();

export const BASE_FRONTEND_URL = getBaseFrontendUrl(environment.systemName);

function getClientProtocol(): string {
    if (environment.production) {
        if (environment.useHttps) {
            return 'https';
        } else {
            return 'http';
        }
    }

    if (!environment.production) {
        if (environment.useHttps) {
            return 'https';
        } else {
            return 'http';
        }
    }

    return 'http';
}

function getApiProtocol(): string {
    if (environment.production) {
        if (environment.useHttps) {
            return 'https';
        } else {
            return 'http';
        }
    }

    if (!environment.production) {
        if (environment.useHttps) {
            return 'https';
        } else {
            return 'http';
        }
    }

    return 'http';
}

// constructs base URL for API host without suffix
function getCurrentApiHost(): string {
    if (environment.production) {
        return `${getApiProtocol()}://${PROD_API_HOST}`;
    }

    return `${getApiProtocol()}://${DEV_API_HOST}`;
}

// constructs base URL for API
export function getBaseApiUrl(systemName?: string): string {
    if (environment.production) {
        return `${getApiProtocol()}://api.${systemName}.${PROD_API_HOST}${
            StringUtils.isNullOrEmpty(PROD_API_HOST_PORT) ? '' : (':' + PROD_API_HOST_PORT)
        }/api`;
    }

    return `${getApiProtocol()}://api.${systemName}.${DEV_API_HOST}${
        StringUtils.isNullOrEmpty(DEV_API_HOST_PORT) ? '' : (':' + DEV_API_HOST_PORT)
    }/api`;
}

export function getBaseFrontendUrl(systemName?: string): string {
    if (systemName === SYSTEM_NAME_AUTH) {
        return `${getClientProtocol()}://${environment.production ? PROD_HOST : DEV_HOST}${
            StringUtils.isNullOrEmpty(environment.production ? PROD_HOST_PORT : DEV_HOST_PORT) ? '' : (':' + (environment.production ? PROD_HOST_PORT : DEV_HOST_PORT))
        }`;
    }
    return `${getClientProtocol()}://${systemName}.${environment.production ? PROD_HOST : DEV_HOST}${
        StringUtils.isNullOrEmpty(environment.production ? PROD_HOST_PORT : DEV_HOST_PORT) ? '' : (':' + (environment.production ? PROD_HOST_PORT : DEV_HOST_PORT))
    }`;
}
