import {Component} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {AppTranslationService} from './common/i18n/app-translation.service';
import {CodebooksService} from './application/services/codebooks.service';
import {AppThemeService} from './application/theme/app-theme.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styles: [`

        #loading {
            position: fixed;
            z-index: 999999;
            height: 100vh;
            width: 100vw;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.27);
            display: none;
            justify-content: center;
            align-items: center;
        }

    `]
})

export class AppComponent {

    menuMode = 'static';

    constructor(
        private primengConfig: PrimeNGConfig,
        private appTranslationService: AppTranslationService,
        private codebooksService: CodebooksService,
        private appThemeService: AppThemeService
    ) {
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        document.documentElement.style.fontSize = '14px';
        this.loadApp();
    }

    async loadApp() {
        const res = await this.appTranslationService.initApp();

        await this.codebooksService.preloadCodebooks();

        this.appThemeService.initTheme();
    }
}
