import {NgModule} from '@angular/core';
import {LangSwitcherComponent} from './lang-switcher.component';
import {CommonModule} from '@angular/common';
import {DirectivesModule} from '../../directives/directives.module';

@NgModule({
	imports: [
		CommonModule,
		DirectivesModule,
	],
	exports: [
		LangSwitcherComponent,
	],
	declarations: [
		LangSwitcherComponent,
	],
})

export class LangSwitcherModule {
}
